*,
*::before,
*::after{
  margin:0;
  padding:0;
  box-sizing: border-box;
}


html{
  font-size:62.5%;
  scroll-behavior: smooth;
  height:100vh;
}
body{
  width: 100vw;
  height: 100vh;
  overflow-x: hidden;
  font-size:1.6em;
  font-family: 'Titillium', sans-serif;
}

body::webkit-scrollbar{
  width: 0;
  background: #F5F5F7;
}

/* Hide scrollbar for Chrome, Safari and Opera */
body::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
body {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}
ul,li{
  list-style: none;
}
header{
  position: absolute;
  top: 30px;
  height:100px;
  display:flex;
  align-items: center;
  justify-content: center;
  background-color: none;
}
section
{
  height: 100vh;
  display:flex;
  align-items: center;
  justify-content: center;
}
p {
  font: 1.125em sans-serif;
  font-weight: light;
  display: inline-block;
}
/*.hero-img{
  filter: invert(1);
}*/
.header-img{
  height: auto;
  width: 100px;
  display:flex;
  align-items: center;
  justify-content: center;
  padding:1em 0;
}
.nav-links {
    display: flex;
    list-style: none;
    text-align: center;
    align-items: center;
}
.nav-item:not(:first-of-type) {
    margin-left: 3.5em;
}

.nav-link {
    color: #333;
    text-decoration: none;
    text-transform: uppercase;
    font-weight: 500;
}
.navbar {
    position: absolute;
    top:110px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 7.5vh;
    background: transparent;
    color: #333;
    padding: 0 5vw;

}
.nav-btn {
    height: fit-content;
    padding: 1em 1.5em;
    background-color: transparent;
    border: 1px solid #fff;
    color: #fff;
    border-radius: 5px;
    cursor: pointer;
}
#hero{
  height: 100vh;
  padding: 10em;
  background: #fff;
}

#footer{
  height: 100vh;
  background: #B5AA9D;
}

/*.title{
  font-size:5rem;
  letter-spacing: 0.4rem;
}*/

/*.heading{
  align-content: center;
  justify-content: center;`
  align-items: center;
  padding:15em;
}*/

#home{
  height: 100vh;
  width: 100%;
  overflow: hidden;
  background:#fff;
}
canvas{
  position: absolute;
  max-width: 100vw;
  max-height: 100vh;
  z-index: 0;
}
.product-content {
  display: block;
  position: relative;
  width: 100%;
  height: 100%;
  max-width: 80vw;
}
.renderbg{
  height: 100vh;
  background-position: center;
  background-size:cover;
  background-repeat: no-repeat;
  background-image: url(assets/render3.png);
}
/*.product-title {
  top: 40%;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-direction: column;
  right: 50px !important;
  width: 100%;
  text-align: left;
  transition: all 0.3s ease-in-out;
  z-index: 2;
}*/

#product-info__description2 {
order:2;
}

#product-info__description4 {
order:4;
}

.product-info {
  top: 44%;
  width: 80vw;
  position: fixed;
  display: flex;
  align-items: center;
  flex-direction: row;
  z-index: 2;
  flex-wrap: wrap;
  justify-content: space-between;
}

.product-info div.product-info__description {
  width: 450px;
  position: relative;
}

.spacer {
  height: 700px;
}

.product-info div.product-info__description{
  position: relative;
  top: 0;
  left: 0;
  color: #86868b;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 19px;
  font-weight: 300;
  letter-spacing: 0.12em;
  line-height: 1.2105263158;
  padding-left: 15px;
  border-left: 2.215px solid #86868b;
}

.highlight{
  color:#333;
  font-size:19px;
  margin-bottom:0.1em;
}

.happypill{
  flex-flow: wrap;
  place-content: center;
  align-items: center;
  width: 80%;
  padding: 0 1em;
}

.brief-img{
  display: flex;
  align-items: center;
  justify-content: center;
}
.capsule-logo{
  width: 100px;
}

.happypill h2 {
  color: black;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 3.25em;
  letter-spacing: 4.9px;
  line-height: 60px;
  text-transform: uppercase;
  text-align: center;
  color: red;
}

.happypill p {
  color: black;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 1.2em;
  line-height: 30px;
  text-align: center;
  margin-top: 1em;
}
.text-active {
  opacity: 1 !important;
  left: 0 !important;
  z-index: 100 !important;
}
#capsuleTitle{
  max-width: 100vw;
  max-height: 100vh;
  z-index: 1000;
}

.hero-img{
  position: absolute;
  max-height: 50vh;
  min-width: 40vw;
}

.red-apple{
  position: absolute;
  min-height: 400px;
  width:10%;
  z-index:-1000;
}

#strains{
  overflow: hidden;
}
.strain-description{
  width: 50%;
}

  .capsule-img{
    width:220px;
    position: absolute;
  }
  .capsule-img-2{
    width:220px;
    position: absolute;
  }
  .capsule-img-3{
    width:220px;
    position: absolute;
  }
  .capsule-img-4{
    width:220px;
    position: absolute;
  }
.pineapplebg-img{
    position: fixed;
    z-index: -1000;
    object-fit: cover;
    background-position: center;
    height: 100%;
    width: 100%;
}

footer {
  background-color: #fff;
  color: #333;
  line-height: 1.5;
}
footer a {
  text-decoration: none;
  color: #333;
}
a:hover {
  color:indianred;
}
.ft-title {
  color: #333;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 2.375rem;
  padding-bottom: 0.625rem;
}

.container {
  flex: 12;
}
/* Footer main */
.ft-main {
  padding: 4.25rem 1.875rem;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
@media only screen and (min-width: 29.8125rem /* 477px */) {
  .ft-main {
    justify-content: space-evenly;
  }
}
@media only screen and (min-width: 77.5rem /* 1240px */) {
  .ft-main {
    justify-content: space-evenly;
  }
}
.ft-main-item {
  padding: 1.25rem;
  min-width: 12.5rem;
}

/* Footer main | Newsletter form */
 .ft-email{
  display: flex;
}
input[type="email"] {
  border: 0;
  padding: 1.625rem;
  margin-top: 0.3125rem;
  width:80%;
 background-color: #ebebeb;
}
input[type="submit"] {
  background-color: #000;
  color: #fff;
  cursor: pointer;
  border: 0;
  padding: 1.625rem 0.9375rem;
  margin-top: 0.3125rem;
}
/* Footer social */
.ft-social {
  padding: 0 1.875rem 1.25rem;
}
.ft-social-list {
  display: flex;
  justify-content: center;
  padding-top: 1.25rem;
}
.ft-social-list li {
  margin: 1rem;
  font-size: 2.25rem;
}
/* Footer legal */
.ft-legal {
  padding: 0.9375rem 1.875rem;
  background-color: #f7f7f7;
}
.ft-legal-list {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}
.ft-legal-list li {
  margin: 0.125rem 0.625rem;
  white-space: nowrap;
}
/* one before the last child */
.ft-legal-list li:nth-last-child(2) {
    flex: 1;
}

.overlay{
  pointer-events: none;
  position: fixed;
  width: 100vw;
  height: 100vh;
  z-index: 1;
  display: flex;
}

.gooddrugs{
  pointer-events: none;
  position: fixed;
  width: 100vw;
  height: 100vh;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
}

.gd{
  max-height: 30%;
}

.bar{
  width: 10vw;
  height: 105vh;
  background:#fff;
}
.counter{
  pointer-events: none;
  position: fixed;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  z-index: 10000;
  color:#ed1a39;
  padding:0.2em 0.4em;
  font-size: 10vw;
  font-family: 'Titillium' sans-serif;
}

video {
    position: absolute;
    z-index: -1;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.herobg{
  position: fixed;
  width: 100vw;
  height: 100vh;
  z-index: -10000;
  opacity: 0.03;
  background-position: center;
  background-size:cover;
  background-repeat: no-repeat;
  background-image: url(https://capsulesequence.s3.us-west-2.amazonaws.com/backgrounds/happybackground.png);
}

#strain-intro{
  display: flex;
  height: 100vh;
  background-color: #fff;
  align-content: center;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}
.strain-good{
  position: absolute;
  width: 560px;
}
#flavors{
  width: 100vw;
  height: 100vh;
  /*display: flex;
  align-items: center;
  justify-content: space-around;
  align-content: center;
  flex-wrap: nowrap;
  flex-direction: row;*/
  overflow: hidden;
}
.flavor-one{
  position: absolute;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-around;
  align-items: center;
  }
  .flavor-two{
  position: absolute;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-around;
  align-items: center;
  }
    .flavor-three{
  position: absolute;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-around;
  align-items: center;
  }
  .flavor-four{
  position: absolute;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-around;
  align-items: center;
  }
  .flavor-five{
  position: absolute;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-around;
  align-items: center;
  }
  .flavor-six{
  position: absolute;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-around;
  align-items: center;
  }
  .flavor-detail{
  display: flex;
  width: 600px;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 0 1em;
  row-gap: 1em;
}
.flavor-detail-p{
  display: flex;
  width: 600px;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  padding: 0 2em;
}
.flavor-detail-2{
  display: flex;
  width: 600px;
  flex-wrap: nowrap;
  align-content: flex-start;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 3.125em;
  row-gap: 1em;
}
.flavor-detail-p-2{
  display: flex;
  width: 600px;
  flex-wrap: nowrap;
  align-content: flex-start;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 3.125em;
  row-gap: 1em;
}
.flavor-detail-p p{
  text-align: left;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 24px;
  font-weight: 300;
  letter-spacing: 0.12em;
  line-height: 1.2105263158;
  padding-left: 15px;
  border-left: 5px solid #0069ad;
}

.flavor-two p{
  text-align: left;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 24px;
  font-weight: 300;
  letter-spacing: 0.12em;
  line-height: 1.2105263158;
  padding-left: 15px;
  border-left: 5px solid #f27898;
}

.flavor-three p{
  text-align: left;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 24px;
  font-weight: 300;
  letter-spacing: 0.12em;
  line-height: 1.2105263158;
  padding-left: 15px;
  border-left: 5px solid #f58a6f;
}
.flavor-four p{
  text-align: left;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 24px;
  font-weight: 300;
  letter-spacing: 0.12em;
  line-height: 1.2105263158;
  padding-left: 15px;
  border-left: 5px solid #72bf44;
}
.flavor-five p{
  text-align: left;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 24px;
  font-weight: 300;
  letter-spacing: 0.12em;
  line-height: 1.2105263158;
  padding-left: 15px;
  border-left: 5px solid #fcd739;
}
.flavor-six p{
  text-align: left;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 24px;
  font-weight: 300;
  letter-spacing: 0.12em;
  line-height: 1.2105263158;
  padding-left: 15px;
  border-left: 5px solid #d01f39;
}


.flavor-detail span{
  font-size: 3em;
  text-transform: uppercase;
}
.flavor-detail img{
  width: 80px;
}

.flavor-detail h2 {
    color: #c4d600;
    display: block;
    font-size: 4.85em;
    text-align: center;
    text-transform: capitalize;
    text-transform: uppercase;
  }

  .flavor-one h2{
    color:#00a3e0;
  }
  .flavor-one span{
    color:#0069ad;
  }

  .flavor-two h2 {
    color: #f27898;
    display: block;
    font-size: 4.85em;
    text-align: center;
    text-transform: capitalize;
    text-transform: uppercase;
  }
  .flavor-two span{
    color:#f59bb3;
  }
  .flavor-three h2 {
    color: #f58a6f;
    display: block;
    font-size: 4.85em;
    text-align: center;
    text-transform: capitalize;
    text-transform: uppercase;
  }
  .flavor-three span{
    color:#f8aaa3;
  }
  .flavor-four h2 {
    color: #72bf44;
    display: block;
    font-size: 4.85em;
    text-align: center;
    text-transform: capitalize;
    text-transform: uppercase;
  }
  .flavor-four span{
    color:#f04f74;
  }
  .flavor-five h2 {
    color: #b8d433;
    display: block;
    font-size: 4.85em;
    text-align: center;
    text-transform: capitalize;
    text-transform: uppercase;
  }
  .flavor-five span{
  color:#fcd739;
}
    .flavor-six h2 {
    color: #ed1a39;
    display: block;
    font-size: 4.85em;
    text-align: center;
    text-transform: capitalize;
    text-transform: uppercase;
  }
  .flavor-six span{
    color:#d01f39;
  }

/*.flavor-bg{
  position: absolute;
  width: 100vw;
  height: 100vh;
  z-index: -10000;
  opacity: 0.03;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url(https://capsulesequence.s3.us-west-2.amazonaws.com/backgrounds/happybackground.png);
}*/
.pineapple-img{
  width: 220px;
}
.blueberry-img{
  width: 220px;
}

#contact{
  overflow: hidden;
}

  //*** MAP LOCATION STYLES ***/

  body {
        color: #404040;
        font:
          400 15px/22px 'Source Sans Pro',
          'Helvetica Neue',
          sans-serif;
        margin: 0;
        padding: 0;
        -webkit-font-smoothing: antialiased;
      }

      * {
        box-sizing: border-box;
      }


     .sidebar {
        position: absolute;
        width: 33.3333%;
        height: 100%;
       /* top: 0;
        left: 0;*/
        overflow: hidden;
        border-right: 1px solid rgb(0 0 0 / 25%);
      }
      .map {
        position: absolute;
        left: 33.3333%;
        width: 66.6666%;
        top: 0;
        bottom: 0;
      }
      .mapboxgl-map{
        height: 100vh;
        position: absolute ;
        top: 0;
        bottom: 0;
      }
     /* h1 {
        font-size: 22px;
        margin: 0;
        font-weight: 400;
        line-height: 20px;
        padding: 20px 2px;
      }*/

      a {
        color: #e52643;
        text-decoration: none;
      }

      a:hover {
        color: #e52643;
      }

      .heading {
        display: flex;
        align-items: center;
        background: #fff;
        text-transform: uppercase;
        border-bottom: 1px solid #eee;
        min-height: 60px;
        line-height: 30px;
        padding: 10px 10px;
        background-color: #e52643;
        color: #fff;
        height: 120px;
        background-image: url('assets/happybg2.png');
        background-position: center;
        background-repeat: no-repeat;
        background-blend-mode: screen;
      }

      .listings {
        height: 100%;
        overflow: auto;
        padding-bottom: 60px;
      }

      .listings .item {
        display: flex;
        flex-direction: column;
        border-bottom: 1px solid #eee;
        padding: 10px;
        text-decoration: none;
        height: 120px;
        align-content: flex-start;
        justify-content: center;
      }

      .listings .item:last-child {
        border-bottom: none;
      }

      .listings .item .title {
        display: block;
        color: #e52643;
        font-weight: 700;
        font-size: 20px;
        letter-spacing: 1px;
        transition: 0.3s;
      }

      .listings .item .title small {
        font-weight: 400;
      }

      .listings .item.active .title,
      .listings .item .title:hover {
        color: #333;
      }

      .listings .item.active {
        background-color: #f8f8f8;
      }

      ::-webkit-scrollbar {
        width: 3px;
        height: 3px;
        border-left: 0;
        background: rgb(0 0 0 / 10%);
      }

      ::-webkit-scrollbar-track {
        background: none;
      }

      ::-webkit-scrollbar-thumb {
        background: #d01f39;
        border-radius: 0;
      }

/*      .marker {
        border: none;
        cursor: pointer;
        height: 100px;
        width: 100px;
      }*/

      /* Marker tweaks */
      /*.mapboxgl-popup {
        padding-bottom: 50px;
      }*/

   /*   .mapboxgl-popup-close-button {
        display: none;
      }

      .mapboxgl-popup-content {
        font:
          400 15px/22px 'Source Sans Pro',
          'Helvetica Neue',
          sans-serif;
        padding: 0 !important;
        width: 180px;
      }

      .mapboxgl-popup-content h3 {
        background: #d01f39;
        color: #fff;
        margin: 0;
        padding: 10px;
        border-radius: 3px 3px 0 0;
        font-weight: 700;
        margin-top: -15px;
      }

      .mapboxgl-popup-content h4 {
        margin: 0;
        padding: 10px;
        font-weight: 800;
      }

       .mapboxgl-popup-content h5 {
        margin: 0;
        padding: 0 10px 10px 10px;
        font-weight: 400;
      }

      .mapboxgl-popup-content div {
        padding: 10px;
      }

      .mapboxgl-popup-anchor-top > .mapboxgl-popup-content {
        margin-top: 15px;
      }

      .mapboxgl-popup-anchor-top > .mapboxgl-popup-tip {
        border-bottom-color: #91c949;
      }
    */
#details{
    display: none;
  }
//*** RESPONSIVE STYLES ***/
@media screen and (max-width: 992px) {
  .subscription-plan {

  }
  .pineapple-img{
    width:170px;
  }
  .flavor-detail h2{
    font-size:2.5em;
  }
  .flavor-detail-p p{
    font-size:14px;
    width: 80vw;
  }
  .flavor-detail span {
    font-size: 2em;
    text-transform: uppercase;
  }
  #details{
    display: none;
  }

}
@media screen and (max-width: 768px) {
  .subscription-list-container {
    flex: 0;
    flex-direction: column;
  }

  #home{
    display: none;
  }

  #details{
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  }

.details-bg{
  background-image: url(assets/detailsmobile.png);
  background-position: center;
  background-size: cover;
  height: 100vh;
  width: 100vw;
  z-index: -1;
  background-position-x: left;
  background-position-y: -9rem;
}
.details-container{
  max-width: 87%;
  color: #86868b;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 300;
}
.product-info__description{
  margin-bottom: 1em;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.625rem;
}
.details-container span{
  font-size: 14px;
}
.details-container h2{
  font-size:16px;
}

  
  .pineapple-img{
    width:130px;
  }
  .flavor-detail {
    display: flex;
    width: 600px;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 0 1em;
    row-gap: .5em;
}
  .flavor-detail span {
    font-size: 2em;
    text-transform: uppercase;
  }
   .flavor-one{
    position: absolute;
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: space-evenly;
    align-items: center;
  }
  .flavor-two{
    position: absolute;
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: space-evenly;
    align-items: center;
  }
  .flavor-three{
    position: absolute;
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: space-evenly;
    align-items: center;
  }
  .flavor-four{
    position: absolute;
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: space-evenly;
    align-items: center;
  }
  .flavor-five{
    position: absolute;
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: space-evenly;
    align-items: center;
  }
  .flavor-six{
    position: absolute;
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: space-evenly;
    align-items: center;
  }

  .flavor-detail-p p {
    text-align: left;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 16px;
    font-weight: 300;
    letter-spacing: 0.12em;
    line-height: 1.2105263158;
    padding-left: 15px;
}

.flavor-detail h2 {

    font-size: 2.85em;
  }
}

@media screen and (min-width: 320px) and (max-width: 480px) {
  .subscription-list-container {
    flex: 0;
    flex-direction: column;
  }
  .flavor-detail{
    row-gap:5px;
  }
  .strain-good{
    width: 340px;
  }
  .pineapple-img{
    width:120px;
  }
  .flavor-detail-p{
    width: 400px;
    
  }
  .flavor-detail-p p{
    font-size: 12px;
  }
  .flavor-detail h2{
    font-size: 1.7em;
  }
  .flavor-detail span {
    font-size: 1.25em;
    text-transform: uppercase;
  }
  .flavor-detail img {
    width: 40px;
}
.hero-img{
  min-width: 20vw;
  max-width: 40vh;
}
.nav-item:not(:first-of-type) {
    margin-left: 1.5em;
}
.nav-item{
  font-size: 14px;
}
header{
  position: absolute;
  top: 10px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: none;
}
.header-img{
  padding:1em 0;
}
.happypill h2 {
color: black;
font-family: Arial, Helvetica, sans-serif;
font-size: 2.25em;
letter-spacing: 4.9px;
line-height: 40px;
text-transform: uppercase;
text-align: center;
color: red;
}
.capsule-logo{
  height: 100px;
}

#home{
display: none;
}

#details{
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  }

.details-bg{
  background-image: url(assets/detailsmobile.png);
  background-position: center;
  background-size: cover;
  height: 100vh;
  width: 100vw;
  z-index: -1;
  background-position-y: -10rem;
}
.details-container{
  max-width: 87%;
  color: #86868b;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 300;
}
.product-info__description{
  margin-bottom: 1em;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.625rem;
}
.details-container span{
  font-size: 10px;
}
.details-container h2{
  font-size:10px;
}

.ft-main{
  justify-content: space-evenly;
}


}
